<template>
  <div class="blok-rte" v-html="content"></div>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const { blok } = toRefs(props);
const content = useRenderRichText(blok.value.content);
</script>

<style lang="scss" scoped>
// Base design
.blok-rte {
  :deep(ul) {
    list-style: disc;
    margin-left: 1rem;
  }

  :deep(h2) {
    @include size-xl;
    span {
      @include size-xl;
    }
  }

  :deep(h3) {
    @include size-l;
    span {
      @include size-l;
    }
  }

  :deep(h4) {
    @include size-m;
    span {
      @include size-m;
    }
  }

  :deep(p) {
    @include size-s;
  }

  :deep(a) {
    color: var(--text-100);
  }

  > :deep(*:not(:first-child)) {
    margin-top: 2rem;
  }

  &.section {
    margin-bottom: 0;
    display: block;
    width: 70%;
    margin: 0 auto;
  }
}
</style>
